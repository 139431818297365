<template>
    <div class="page-list">
        <div class="page-left">共 {{total}} 条记录 第 {{pages}} / {{totalPages}} 页</div>
        <el-pagination
            background
            layout="prev, pager, next"
            :total="total"
            :page-size.sync="pagesSize"
            @current-change="handleCurrentChange"
        >
        </el-pagination>
    </div>
</template>

<script>
    export default {
        props: {
            pagesSize: {
                type: Number,
                default: 15
            },
            total: {
                type: [ String, Number ],
                default: 0
            },
            pages: {
                type: [ String, Number ],
                default: 0
            },
            totalPages: {
                type: [ String, Number ],
                default: 0
            }
        },
        methods: {
            handleCurrentChange(e) {
                this.$emit('changePage', e)
            }
        }
    }
</script>

<style lang="scss" scoped>
.page-list{
    margin-top: 16px;
    @include flex(center,space-between);
    .page-left{
        @include font(14px,#888FA4);
    }
    ::v-deep .el-pagination{
        .el-pager{
            li{
                &.active{
                    background: $main_color !important;
                }
            }
        }
    }
}
</style>