<template>
    <div class="detail-warp">
        <div class="detail-warp-content">
            <div class="detail-header"></div>
            <div class="detail-content">
                <div class="user-info-header">
                    <div class="user-info-header-left">个人信息</div>
                    <el-button type="primary" @click="deposit">提现申请</el-button>
                </div>
                <ul>
                    <li>
                        <span>ID</span>
                        <span>{{recordInfo.id}}</span>
                    </li>
                    <li>
                        <span>姓名</span>
                        <span>{{recordInfo.nickname}}</span>
                    </li>
                    <li>
                        <span>分享获客</span>
                        <span>{{recordInfo.invite_apply_submit_count}}</span>
                    </li>
                    <li>
                        <span>成交获客</span>
                        <span>{{recordInfo.deal_count}}</span>
                    </li>
                    <li>
                        <span>下级充值金额</span>
                        <span>￥{{recordInfo.son_recharge_total}}</span>
                    </li>
                    <li>
                        <span>下级消费金额</span>
                        <span>￥{{recordInfo.son_spend_total}}</span>
                    </li>
                    <li>
                        <span>待返佣金</span>
                        <span>￥{{recordInfo.wait_money}}</span>
                    </li>
                    <li>
                        <span>可提现佣金</span>
                        <span>￥{{recordInfo.withdraw_money}}</span>
                    </li>
                    <li>
                        <span>已提现佣金</span>
                        <span>￥{{recordInfo.already_withdraw}}</span>
                    </li>
                </ul>
                <div class="but-list">
                    <div 
                        :class="['but-item',activeIdx==index?'active':'']" 
                        v-for="(item,index) in butList" 
                        :key="index"
                        @click="changeRecord(index)"
                    >
                        {{item}}
                    </div>
                </div>
                <div v-if="activeIdx==0">
                    <userTable :tableData="bountyList" :tableKeyList="tableKeyList"/>
                    <usePagination 
                        v-if="bountyList.length"
                        :pagesSize="bountyPages.pagesize" 
                        :pages="bountyPages.page"
                        :total="pageInfo.total"
                        :totalPages="pageInfo.last_page"
                        @changePage="changePage"
                    />
                </div>
                <div v-else-if="activeIdx == 1">
                    <userTable  :tableData="recordTableData" :tableKeyList="recordTableKey"/>
                    <usePagination 
                        v-if="recordTableData.length"
                        :pagesSize="depositPages.pagesize" 
                        :pages="depositPages.page"
                        :total="recordPageInfo.total"
                        :totalPages="recordPageInfo.last_page"
                        @changePage="changeRecordPage"
                    />
                </div>
                <div v-else>
                    <userTable :tableData="refundTableData" :tableKeyList="refundTableKey"/>
                    <usePagination 
                        v-if="refundTableData.length"
                        :pagesSize="refundPages.pagesize" 
                        :pages="refundPages.page"
                        :total="refundTotal.total"
                        :totalPages="refundTotal.last_page"
                        @changePage="changeRefundPage"
                    />
                </div>
            </div>
        </div>
        <el-dialog
            title="佣金提现申请"
            :visible.sync="isVisible"
            width="450px"
        >
            <el-form label-position="right"  :model="formData" :rules="rules" ref="applyForm" label-width="100px">
                <el-form-item prop="user_name" label="申请姓名">
                    <el-input v-model.trim="formData.user_name" placeholder="请输入"></el-input>
                </el-form-item>
                 <el-form-item label="提现方式">
                    <el-select v-model="formData.type" @change="changeType" placeholder="请选择">
                        <el-option
                            v-for="item in withdrawType"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <div v-if="formData.type == 0">
                    <el-form-item prop="bank_name" label="提现银行">
                        <el-input v-model.trim="formData.bank_name"  placeholder="请输入"></el-input>
                    </el-form-item>
                    <el-form-item prop="branch_bank_name" label="开户支行">
                        <el-input v-model.trim="formData.branch_bank_name"  placeholder="请输入"></el-input>
                    </el-form-item>
                    <el-form-item prop="bank_card" label="卡号">
                        <el-input v-model.trim="formData.bank_card"  placeholder="请输入"></el-input>
                    </el-form-item>
                    <el-form-item prop="addressPosition" label="开户所在地">
                        <el-cascader
                            v-model="formData.addressPosition"
                            :options="addressOptions"
                            @change="handleChange"
                            @expand-change="handleExpand"
                        >
                        </el-cascader>
                    </el-form-item>
                </div>
                <el-form-item v-else prop="bank_card" label="账号">
                    <el-input v-model.trim="formData.bank_card"  placeholder="请输入"></el-input>
                </el-form-item>
                
                <div class="money">可提现金额：￥{{recordInfo.withdraw_money}}</div>
                <el-form-item label="提现金额">
                    <el-input  placeholder="" readonly :value="recordInfo.withdraw_money"></el-input>
                </el-form-item>
                <el-form-item label="备注" prop="remark">
                    <el-input type="textarea" resize="none" v-model.trim="formData.remark"  placeholder="请输入"></el-input>
                </el-form-item>
                <el-form-item class="form-buts">
                    <el-button @click="isVisible = false">取 消</el-button>
                    <el-button type="primary" :loading="isLoading" @click="submitDeposit">确 定</el-button>
                </el-form-item>
            </el-form>
            
        </el-dialog>
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    import { validaCart, validaZH, validaAlipay } from '@/utils/valida'
    import userTable from '@/components/userTable'
    import usePagination from '@/components/usePagination'
    export default {
        data() {
            return {
                butList: [ '佣金记录', '提现记录', '下级退款记录' ],
                activeIdx: 0,
                bountyList: [],
                tableKeyList: [
                    {
                        key: 'id',
                        name: '客户ＩＤ'
                    },
                    {
                        key: 'nickname',
                        name: '客户姓名'
                    },
                    {
                        key: 'create_time_str',
                        name: '消费时间'
                    },
                    {
                        key: 'money',
                        name: '消费金额',
                        unit: '￥'
                    },
                    {
                        key: 'commission',
                        name: '佣金',
                        unit: '￥'
                    },
                    {
                        key: 'status_name',
                        name: '佣金状态'
                    }
                ],
                recordTableData: [],
                recordTableKey: [
                    {
                        key: 'create_time_str',
                        name: '提现时间'
                    },
                    {
                        key: 'money',
                        name: '提现金额',
                        unit: '￥'
                    },
                    {
                        key: 'statusText',
                        name: '提现状态'
                    }
                ],
                /**退款 */
                refundTableKey: [
                    {
                        key: 'user_id',
                        name: '客户ID'
                    },
                    {
                        key: 'nickname',
                        name: '客户姓名'
                    },
                    {
                        key: 'money',
                        name: '退款金额',
                        unit: '￥'
                    },
                    {
                        key: 'create_time_str',
                        name: '退款时间'
                    },
                    {
                        key: 'commission',
                        name: '退款(部分)后佣金',
                        unit: '￥'
                    }
                ],
                refundTableData: [],
                isVisible: false,
                formData: {
                  user_name: '',
                  bank_name: '',
                  branch_bank_name: '',
                  bank_card: '',
                  remark: '',
                  province: '',
                  city: '',
                  addressPosition: [],
                  type: ''
                },
                withdrawType: [], // 提现类型
                rules: {
                    user_name: [
                      { validator: this.validaName, trigger: 'blur', message: '请输入中文姓名'}
                    ],
                    bank_name: [
                      { validator: this.validaName, trigger: 'blur', message: '提现银行请输入中文' }
                    ],
                    branch_bank_name: [
                        { validator: this.validaName, trigger: 'blur', message: '开户所在地请输入中文' }
                    ],
                    bank_card: [
                        { validator: this.validaCart, trigger: 'blur'}
                    ],
                    addressPosition: [
                        {validator: this.validaAddress, trigger: 'change', message: '请选择开户所在地址' }
                    ]
                },
                addressOptions: [],
                bountyPages: {
                    page: 1,
                    pagesize: 15
                },
                pageInfo: {
                    total: 0,
                    last_page: 0
                },
                depositPages: {
                    page: 1,
                    pagesize: 15
                },
                recordPageInfo: {
                    page: 1,
                    pagesize: 15
                },
                isLoading: false,
                refundPages: {
                    page: 1,
                    pagesize: 15
                },
                refundTotal: {
                    total: 0,
                    last_page: 0
                }
            }
        },
        components: {
            userTable,
            usePagination
        },
        computed: {
            ...mapState([ 'recordInfo' ])
        },
        created(){
            this.$store.dispatch('getMyRecord')
            this.getBountyList()
            this.getAddress()
            this.getWithdrawType()
        },
        methods: {
            validaName(rule, value, callback) {
              if(validaZH(value)) {
                callback()
              } else {
                callback(new Error(''))
              }
            },
            validaCart(rule, value, callback) {
                if(this.formData.type == 0) {
                    if(validaCart(value)) {
                        callback()
                    } else {
                        callback(new Error('请输入正确的卡号'))
                    }
                } else {
                    if(validaAlipay(value)) {
                        callback()
                    } else {
                        callback(new Error('请输入账号'))
                    }
                }
              
            },
            validaAddress(rule, value, callback) {
              if(value.length) {
                callback()
              } else {
                callback(new Error(''))
              }
            },
            changeRecord(idx) {
                if(this.activeIdx == idx) return
                this.activeIdx = idx
                this.bountyList = []
                this.recordTableData = []
                if(idx == 0) {
                    this.bountyPages.page = 1
                    this.getBountyList()
                } else if(idx == 1) {
                    this.depositPages.page = 1
                    this.getWithdrawalList()
                } else {
                    this.refundPages.page = 1
                    this.getRefundList()
                }
            },
            handleChange(e){
                this.formData.province = e[0]
                this.formData.city = e[1]
            },
            //选择提现类型
            changeType() {
                this.$refs.applyForm.resetFields()
            },
            //提现类型
            getWithdrawType() {
                this.$getHttp(this.$api.getWithdrawType).then(res => {
                    this.withdrawType = res.data
                    this.formData.type = res.data[0].id
                })
            },
            //省
            handleExpand(e) {
                const code = e[0]
                const idx = this.addressOptions.findIndex(item => item.value == code)
                this.addressOptions[idx].children.length == 0 && this.getAddress(code)
            },
            //佣金页码
            changePage(e){
                this.bountyPages.page=e
                this.getBountyList()
            },
            //记录页码
            changeRecordPage(e) {
                this.depositPages.page = e
                this.getWithdrawalList()
            },
            //退款记录页码
            changeRefundPage(e){
                this.refundPages.page = e
                this.getRefundList()
            },
            //提现
            deposit() {
              if(this.recordInfo.withdraw_money == '0.00') {
                this.$errorPrompt('暂无可提佣金')
                return
              }
              this.isVisible = true
              this.isLoading = false
              this.$nextTick(()=>{
                this.$refs.applyForm.resetFields()
              })
            },
            // 确定
            submitDeposit() {
              this.$refs.applyForm.validate((valid) => {
                    if (valid) {
                        const otherType = [ 'bank_name', 'branch_bank_name', 'province', 'city']
                        this.isLoading = true
                        let formData = { ...this.formData}
                        delete formData.addressPosition
                        // 0 银行卡
                        if(this.formData.type != 0) {
                            otherType.forEach(item => {
                               delete formData[item]
                            })
                        }
                        this.$postHttp(this.$api.postApplyWithraw, formData).then(res => {
                            if(res.code == 1) {
                                this.$successPrompt(res.msg)
                                this.isVisible = false
                                this.$store.dispatch('getMyRecord')
                                if(this.activeIdx == 1) {
                                    this.depositPages.page = 1
                                    this.getWithdrawalList()
                                }
                            }
                            this.isLoading = false
                        })
                    } 
                });
            },
            getAddress(code=0) {
                this.$getHttp(this.$api.getAddress, { code }).then(res=>{
                    if(code == 0) {
                        this.addressOptions = res.data.map(item => {
                        return {
                            value: item.code,
                            label: item.name,
                            children: []
                        }
                        })
                    } else {
                        const idx = this.addressOptions.findIndex(item => item.value == code)
                        this.addressOptions[idx].children = res.data.map(item => {
                        return {
                            value: item.code,
                            label: item.name
                        }
                        })
                    }
                    
                })
            },
            // 佣金记录
            getBountyList() {
                this.$getHttp(this.$api.getCommissionRecord, this.bountyPages).then(res => {
                    const { list, total, last_page } = res.data
                    this.pageInfo.total = total
                    this.pageInfo.last_page = last_page
                    this.bountyList = list
                })
            },
             // 提现佣金
            getWithdrawalList() {
                this.$getHttp(this.$api.getWithdrawList, this.depositPages).then(res => {
                    const { list, total, last_page } = res.data
                    const recordList = list.map(item => {
                        item.statusText = item.status == 0 ? item.settle_name : item.status_name
                        return item
                    })
                    this.recordPageInfo.total = total
                    this.recordPageInfo.last_page = last_page
                    this.recordTableData = recordList
                })
            },
            // 下级佣金退款记录
            getRefundList(){
                this.$getHttp(this.$api.getRefundList, this.refundPages).then(res => {
                    const { list, total, last_page } = res.data
                    this.refundTotal.total = total
                    this.refundTotal.last_page = last_page
                    this.refundTableData = list
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
.detail-warp{
    @include flex();
    padding: 38px 0;
    min-height: 100%;
    min-width: 1200px;
    background: $main_bg_color;
    .detail-warp-content{
        width: 1000px;
        min-height: 892px;
        border-radius: 12px;
        box-shadow: 0px 30px 60px rgba(41, 76, 172, 0.2);
        background: #fff;
        .detail-header{
            height: 68px;
            background: url('../assets/image/rules_bg.png');
            background-size: 100% 100%;
        }
        .detail-content{
            padding: 60px 80px;
            .user-info-header{
                @include flex(center,space-between);
                margin-bottom: 17px;
                .user-info-header-left{
                    @include font(16px);
                    position: relative;
                    padding-left: 11px;
                    flex: 1;
                    &::after{
                        content: '';
                        position: absolute;
                        left: 0;
                        top: 56%;
                        transform: translateY(-50%);
                        @include wh(3px,16px);
                        background: $main_color;
                    }
                    &::before{
                        content: '';
                        position: absolute;
                        right: 13px;
                        top: 50%;
                        transform: translateY(-50%);
                        @include wh(651px,0);
                        border: 1px dashed #D8DEEB;
                    }
                }
                ::v-deep .el-button{
                    @include wh(88px,32px);
                    line-height: 32px;
                    @include font(14px,#fff,center);
                    border-radius: 2px;
                    padding: 0;
                    &.el-button--primary{
                        background: $main_color;
                        border-color: $main_color;
                    }
                }
            }
            ul{
                @include flex(center,flex-start);
                flex-wrap: wrap;
                border-top: 1px solid #F2F5FF;
                border-left: 1px solid #F2F5FF;
                li{
                @include wh(50%,46px);
                @include flex(center,flex-start);
                border-bottom: 1px solid #F2F5FF;
                border-right: 1px solid #F2F5FF;
                    span{
                        padding-left: 24px;
                        &:first-child{
                            padding-left:0;
                            @include wh(120px,45px);
                            background: #F9FAFE;
                            @include font(14px,#A2AAC1,center);
                            line-height: 45px;
                        }
                    }
                }
            }
            .but-list{
                @include flex(center,flex-start);
                @include font(16px,#A2AAC1);
                margin-top: 32px;
                border-bottom: 1px solid #F2F5FF;
                margin-bottom: 16px;
                .but-item{
                    margin-right: 50px;
                    position: relative;
                    padding-bottom: 16px;
                    cursor: pointer;
                    &.active{
                        color: $main_color;
                        &::after{
                            position: absolute;
                            content: '';
                            left: 0;
                            bottom: 0;
                            @include wh(100%,4px);
                            background: $main_color;
                        }
                    }
                }
            }
        }
    }
    ::v-deep .el-dialog__body{
        padding-bottom: 0.5px;
        .el-form-item{
            margin-bottom: 24px;
            .el-form-item__label{
                padding-right: 24px;
            }
            .el-select{
                width: 100%;
            }
        }
        .el-input__inner{
            height: 32px;
            @include font(14px,$main_font_color);
            line-height: 32px;
        }
        .el-textarea__inner{
            height: 80px;
        }
        .money{
            @include font(12px,#F1524D);
            padding-left: 100px;
        }
        .el-cascader{
            width: 100%;
        }
        .form-buts{
            text-align: right;
            .el-button{
                @include wh(60px,32px);
                line-height: 32px;
                padding: 0;
                &.el-button--primary{
                    background: $main_color;
                    border-color: $main_color;
                }
            }
        }
    }
}

</style>