<template>
    <div class="table">
        <el-table
            :data="tableData"
            border
            style="width: 100%"
        >
            <el-table-column
                v-for="(item,index) in tableKeyList"
                :key="index"
                :prop="item.key"
                :label="item.name"
                align="center"
                show-overflow-tooltip
            >
                <template slot-scope="scope">
                    <div>{{ (item.unit || '')+(scope.row[item.key] || '-')}}</div>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
    export default {
        props: {
            tableData: {
                type: Array,
                default: () => []
            },
            tableKeyList: {
                type: Array,
                default: () => []
            }
        }
    }
</script>
<style lang="scss" scoped>
.table{
    ::v-deep .el-table__row{
        td{
            height: 46px;
            padding: 0;
        }
    }
}
</style>
